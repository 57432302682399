import React from "react";
import "../custom/style.css";
import { Link } from "react-router-dom";
import { Row, Col, Navbar, Nav, Container } from "react-bootstrap";

function Footer() {
  return (
    <div className="justify-content-center footerOverall pt-5 pb-2">
      {
        <div className="main-footer">
          <div className="container">
            <div className="row">
              <Col className="me-auto">
                <img
                  src={require("../images/logoWithTitle.png")}
                  style={{ maxWidth: "200px" }}
                ></img>
              </Col>
              {/*Column 2*/}
              <div className="col-md-3 col-sm-3 mt-3 screenMargin">
                <h4 className="footerHeader">LINKS</h4>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/about"></Link>
                    <p className="footerSubLinks">About Us</p>
                  </li>
                  <li>
                    <a href="" className="footerSubLinks"></a>
                  </li>
                  <li>
                    <a href="#" className="footerSubLinks">
                      Returns and Refunds
                    </a>
                  </li>
                </ul>
              </div>
              {/*Column 3*/}
              <div className="col-md-3 col-sm-3 mt-3">
                <h4 className="footerHeader">Social</h4>
                <ul className="list-unstyled ">
                  <li className="disabled-link">
                    <a href="#" className="footerSubLinks">
                      <i class="fab fa-facebook-f"></i> &nbsp; Facebook
                    </a>
                  </li>
                  <li className="disabled-link">
                    <a href="#" className="footerSubLinks">
                      <i class="fab fa-twitter"></i>&nbsp; Twitter
                    </a>
                  </li>
                  <li className="disabled-link">
                    <a href="#" className="footerSubLinks">
                      <i class="fab fa-instagram"></i> &nbsp; Instagram
                    </a>
                  </li>
                  <li className="disabled-link">
                    <a href="#" className="footerSubLinks">
                      <i class="fab fa-youtube"></i> &nbsp;YouTube
                    </a>
                  </li>
                </ul>
              </div>
              <Col md={3} xl={3}>
                <h3 className="mt-3 footerHeader">CONTACT </h3>
                <ul className="list-unstyled">
                  <li>
                    <a
                      href="mailto:inbox@exia.co.in"
                      className="footerSubLinks"
                    >
                      <i class="fas fa-envelope"></i> inbox@exia.co.in
                    </a>
                  </li>
                  <li className="mt-2">
                    <a href="tel:+918754519815" className="footerSubLinks">
                      <i class="fas fa-phone"></i> +91 87545 19815
                    </a>
                  </li>
                  <li className="mt-2">
                    <a href="#" className="footerSubLinks">
                      <i class="fas fa-map-marker-alt"></i>
                      {"    "}Chennai, India
                    </a>
                  </li>
                </ul>
              </Col>
            </div>

            <Row className="text-center mt-5 footerCopyrights">
              <p classname="footerCopyrights">
                Made with <i class="fas fa-heart heart"></i> at
                <strong>
                  <a className="footerExia" href="#">
                    {" "}
                    Chennai
                  </a>
                </strong>
              </p>
            </Row>
          </div>
        </div>
      }
    </div>
  );
}

export default Footer;

<span className="mx-3 socialLogo">
  <i class="fa-brands fa-facebook"></i>
</span>;
