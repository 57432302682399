import { BrowserRouter as Router, Route } from "react-router-dom";
import "./bootstrap.min.css";
import "./index.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import AboutScreen from "./screens/AboutScreen";
import UiuxScreen from "./screens/UiuxScreen";
import WebScreen from "./screens/WebScreen";
import BrandingScreen from "./screens/BrandingScreen";
import DevelopmentScreen from "./screens/DevelopmentScreen";
import TempleTownTrips from "./screens/projects/TempleTownTrips";

function App() {
  return (
    <Router>
      <Header />
      <main>
        <Route path="/" component={HomeScreen} exact />
        <Route path="/about" component={AboutScreen} />
        <Route path="/branding" component={BrandingScreen} />
        <Route path="/uiux" component={UiuxScreen} />
        <Route path="/dev" component={DevelopmentScreen} />
        <Route path="/web" component={WebScreen} />
        <Route path="/projects/templetowntrips" component={TempleTownTrips} />
      </main>
      <Footer />
    </Router>
  );
}

export default App;
