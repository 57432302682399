const uiuxs = [
  {
    _id: "1",
    name: "CazuHome",
    image: "/images/uiux/cazu.jpg",
    description: "Fresh and healthy from gardens across California",
    brand: "Exia",
    category: "nuts",
  },
  {
    _id: "2",
    name: "CazuProductEdit",
    image: "/images/uiux/cazu2.jpg",
    description: "Fresh and healthy from gardens across California",
    brand: "Exia",
    category: "nuts",
  },
  {
    _id: "3",
    name: "CazuOrderList",
    image: "/images/uiux/cazu3.jpg",
    description: "Fresh and healthy from gardens across California",
    brand: "Exia",
    category: "nuts",
  },
  {
    _id: "4",
    name: "DogsNShowsHome",
    image: "/images/uiux/dns.jpg",
    description: "Fresh and healthy from gardens across California",
    brand: "Exia",
    category: "dryFruits",
  },
  {
    _id: "5",
    name: "DogsNShowsProduct",
    image: "/images/uiux/dns2.jpg",
    description: "Fresh and healthy from gardens across California",
    brand: "Exia",
    category: "nuts",
  },
];
export default uiuxs;
