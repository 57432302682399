import React from "react";
import "../custom/style.css";
import Aos from "aos";
import "aos/dist/aos.css";

import { Row, Col, Container, Image, Card, Button } from "react-bootstrap";

function WebScreen() {
  return (
    <div className="highMarginTop">
      <Container>
        <Row className="homeIntro">
          <span data-aos="fade-up" className=" text-center homeHello p-0">
            Web Solutions
          </span>
          <Row data-aos="fade-up">
            <Col className=" text-center customCol px-0">
              <span>The best solutions for your spark !</span>
            </Col>
            <Row className="mt-3 text-center">
              <Col>
                <Button className="baseButton btn-sm">Discuss With Us</Button>
              </Col>
            </Row>
          </Row>

          <Col classNmae="homeIntroSecondColumn" id="about"></Col>
        </Row>
        <Row>
          <Col data-aos="fade-up">
            <span className="homeNavigate">
              Take a tour of the projects we've done &nbsp;
              <span className="beginningText px-2">&nbsp;so far .&nbsp;</span>
            </span>
          </Col>
          <Col>
            <Row>
              <Col data-aos="fade-up" className="p-5">
                Here at Exia Solutions, in our palms, we hold a map with warm
                smiles on our face to listen to your{" "}
                <span className="font-weight-bold">idea</span> and hustle our
                way to make it a reality, while you can sit back and enjoy a cup
                of tea..
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="highMarginRow">
          <Col className="mx-3">
            <Card data-aos="fade-left" className="baseCard p-3">
              <Card.Body>
                <Row>
                  <Col>
                    <Card.Title className="crazyNumbers">1</Card.Title>
                  </Col>
                  <Col className="m-auto text-center">
                    <img
                      data-aos="fade-down"
                      src={require("../images/branding.png")}
                    />
                  </Col>
                </Row>
                <Card.Title className="mb-3 baseCardTitle">Branding</Card.Title>
                <Card.Text className="baseCardText">
                  Building the idea or image people have in mind when some
                  product strikes their thought. The impact branding has is as
                  equal as the idea in itself
                </Card.Text>
                <Button className="baseButton btn-sm">Explore</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="mx-3">
            <Card data-aos="fade-left" className="baseCard p-3">
              <Card.Body>
                <Row>
                  <Col>
                    <Card.Title className="crazyNumbers">2</Card.Title>
                  </Col>
                  <Col className="m-auto text-center">
                    <img
                      data-aos="fade-down"
                      src={require("../images/uiux.png")}
                    />
                  </Col>
                </Row>
                <Card.Title className="mb-3 baseCardTitle">
                  UI&nbsp;/&nbsp;UX design
                </Card.Title>
                <Card.Text className="baseCardText">
                  Our team here at Exia, give it all to develop the best
                  interface and experience any user can get a.k.a Making them
                  fall in love with what they see !
                </Card.Text>
                <Button className="baseButton btn-sm">Explore</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="mx-3">
            <Card data-aos="fade-left" className="baseCard p-3">
              <Card.Body>
                <Row>
                  <Col>
                    <Card.Title className="crazyNumbers">3</Card.Title>
                  </Col>
                  <Col className="m-auto text-center">
                    <img
                      data-aos="fade-down"
                      src={require("../images/development.png")}
                    />
                  </Col>
                </Row>
                <Card.Title className="mb-3 baseCardTitle">
                  Development
                </Card.Title>
                <Card.Text className="baseCardText">
                  Whatever be the software requierment, we got you covered with
                  the highly talented under-one-roof team that strives the smart
                  way and seamless !
                </Card.Text>
                <Button className="baseButton btn-sm">Explore</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default WebScreen;
