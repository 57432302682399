import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../custom/BrandingScreen.css";
import {
  Row,
  Col,
  Container,
  Image,
  Card,
  Button,
  Carousel,
} from "react-bootstrap";
import { Link, LinkContainer } from "react-router-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";

import brandings from "../branding.js";

function DevelopmentScreen() {
  let history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <Container className="">
        <Row className="mt-5">
          <Col>
            <Button
              className="secondaryButton px-3 py-0"
              onClick={() => history.goBack()}
            >
              {"<"}
            </Button>
          </Col>
        </Row>
        <Row className="text-center">
          <p className="subpageHeading" data-aos="fade-up">
            Development
          </p>
        </Row>
        <Row className="mt-3 justify-content-center">
          <Col xl={8}>
            <Row className="mt-3 mx-0 p-0">
              <Col className="d-flex justify-content-center">
                <Row className="m-0 p-0 justify-content-center">
                  <Card className="d-flex baseCard p-3" data-aos="fade-up">
                    <Card.Body>
                      <Row>
                        <Col>
                          <Card.Title
                            className="crazyNumbers2"
                            data-aos="fade-up"
                          >
                            <i class="fa-solid fa-gears"></i>{" "}
                          </Card.Title>
                        </Col>
                      </Row>
                      <Card.Title className="mb-3 mt-2 baseCardTitle">
                        Technical Expertise
                      </Card.Title>
                      <Card.Text
                        className="baseCardText"
                        style={{ lineHeight: "2" }}
                      >
                        <span>
                          Exia offers technical{" "}
                          <span className="highlightText">expertise</span> in
                          software development. Our skilled dev team has
                          expertise in programming languages and methodologies.
                          We provide
                          <span className="highlightText">
                            cutting-edge solutions
                          </span>{" "}
                          and stay current with industry trends. Our{" "}
                          <span className="highlightText">
                            collaborative approach
                          </span>{" "}
                          ensures custom solutions for clients. We handle all
                          aspects of software dev from project management to
                          security and optimization.
                          <br />
                          <span className="highlightText">
                            Get in touch
                          </span>{" "}
                          today to learn how we can support your growth.
                        </span>
                      </Card.Text>
                      <Row>
                        <Col xl={4} xs={12}>
                          <Row className="m-2 p-0">
                            <LinkContainer to="/branding">
                              <Button
                                className="secondaryButton btn-sm"
                                data-aos="fade-up"
                                disabled
                              >
                                Web Applications
                              </Button>
                            </LinkContainer>
                          </Row>
                        </Col>
                        <Col xl={4} xs={12}>
                          <Row className="m-2 p-0">
                            <LinkContainer to="/uiux">
                              <Button
                                className="secondaryButton btn-sm"
                                data-aos="fade-up"
                                disabled
                              >
                                Cloud Services
                              </Button>
                            </LinkContainer>
                          </Row>
                        </Col>
                        <Col xl={4} xs={12}>
                          <Row className="m-2 p-0">
                            <LinkContainer to="/branding">
                              <Button
                                className="secondaryButton btn-sm"
                                data-aos="fade-up"
                                disabled
                              >
                                Machine Learning
                              </Button>
                            </LinkContainer>
                          </Row>
                        </Col>
                        <Col xl={4} xs={12}>
                          <Row className="m-2 p-0">
                            <LinkContainer to="/">
                              <Button
                                className="secondaryButton btn-sm"
                                disabled
                                data-aos="fade-up"
                              >
                                Mobile Apps
                              </Button>
                            </LinkContainer>
                          </Row>
                        </Col>
                        <Col xl={4} xs={12}>
                          <Row className="m-2 p-0">
                            <Button
                              className="secondaryButton btn-sm"
                              disabled
                              data-aos="fade-up"
                            >
                              IoT and Automation
                            </Button>
                          </Row>
                        </Col>
                        <Col xl={4} xs={12}>
                          <Row className="m-2 p-0">
                            <Button
                              className="secondaryButton btn-sm"
                              disabled
                              data-aos="fade-up"
                            >
                              Custom Solutions
                            </Button>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="highMarginTop mb-0">
          <img
            src={require("../images/stack.png")}
            style={{}}
            data-aos="fade-up"
          ></img>
        </Row>
        <Row className="text-center">
          <p className="mt-3" data-aos="fade-up">
            & passion
          </p>
        </Row>
      </Container>
    </div>
  );
}

export default DevelopmentScreen;
