const projects = [
  {
    _id: "1",
    name: "Temple Town Trips",
    image: "/images/projects/TempleTownTrips.jpg",
    description:
      "Tourism Website for Temples and soon for all your favourite destinations",
    brand: "Exia",
  },
];
export default projects;
