import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../custom/BrandingScreen.css";
import {
  Row,
  Col,
  Container,
  Image,
  Card,
  Button,
  Carousel,
} from "react-bootstrap";
import { Link, LinkContainer } from "react-router-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";

import brandings from "../branding.js";

function AboutUsScreen() {
  let history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <Container className="">
        <Row className="mt-5">
          <Col>
            <Button
              className="secondaryButton px-3 py-0"
              onClick={() => history.goBack()}
            >
              {"<"}
            </Button>
          </Col>
        </Row>

        <Row className="mt-5 text-center">
          <p className="subpageHeading" data-aos="fade-up">
            About Us
          </p>
        </Row>
        <Row className="mt-3 justify-content-center">
          <Col>
            <Row className="mt-3 mx-0 p-0">
              <Col className="d-flex justify-content-center">
                <Row className="m-0 p-0 justify-content-center">
                  <Card className="d-flex baseCard p-3" data-aos="fade-up">
                    <Card.Body>
                      <Row>
                        <Col>
                          <Card.Title
                            className="crazyNumbers2"
                            data-aos="fade-up"
                          >
                            <i class="fa-solid fa-heart"></i>
                          </Card.Title>
                        </Col>
                      </Row>
                      <Card.Title className="mb-3 mt-2 baseCardTitle">
                        Hey There !
                      </Card.Title>
                      <Card.Text
                        className="baseCardText"
                        style={{ lineHeight: "2" }}
                      >
                        <span>
                          Exia offers expert{" "}
                          <span className="highlightText">
                            web application development
                          </span>{" "}
                          to enhance business efficiency and improve
                          productivity. Our{" "}
                          <span className="highlightText">
                            custom solutions
                          </span>{" "}
                          cater to each client's unique needs, prioritizing
                          user-friendly design and functionality. Using
                          cutting-edge technology, our scalable, secure, and{" "}
                          <span className="highlightText">
                            user-friendly applications
                          </span>{" "}
                          empower businesses to reach their full potential.{" "}
                          <span className="highlightText">Get in touch</span>{" "}
                          today to learn how we can support your growth.
                        </span>
                      </Card.Text>
                      <Row>
                        <Col xl={4} xs={12}>
                          <Row className="m-2 p-0">
                            <LinkContainer to="/branding">
                              <Button
                                className="secondaryButton btn-sm"
                                data-aos="fade-up"
                              >
                                Branding
                              </Button>
                            </LinkContainer>
                          </Row>
                        </Col>
                        <Col xl={4} xs={12}>
                          <Row className="m-2 p-0">
                            <LinkContainer to="/uiux">
                              <Button
                                className="secondaryButton btn-sm"
                                data-aos="fade-up"
                              >
                                UI / UX
                              </Button>
                            </LinkContainer>
                          </Row>
                        </Col>
                        <Col xl={4} xs={12}>
                          <Row className="m-2 p-0">
                            <LinkContainer to="/dev">
                              <Button
                                className="secondaryButton btn-sm"
                                data-aos="fade-up"
                              >
                                Development
                              </Button>
                            </LinkContainer>
                          </Row>
                        </Col>
                        <Col xl={4} xs={12}>
                          <Row className="m-2 p-0">
                            <LinkContainer to="/">
                              <Button
                                className="secondaryButton btn-sm"
                                disabled
                                data-aos="fade-up"
                              >
                                E-Commerce Application
                              </Button>
                            </LinkContainer>
                          </Row>
                        </Col>
                        <Col xl={4} xs={12}>
                          <Row className="m-2 p-0">
                            <Button
                              className="secondaryButton btn-sm"
                              disabled
                              data-aos="fade-up"
                            >
                              AI / ML Applications
                            </Button>
                          </Row>
                        </Col>
                        <Col xl={4} xs={12}>
                          <Row className="m-2 p-0">
                            <Button
                              className="secondaryButton btn-sm"
                              disabled
                              data-aos="fade-up"
                            >
                              Real Time Systems
                            </Button>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="text-center mt-5">
          <p className="subSubHeading" data-aos="fade-up">
            Contact us for more details
          </p>
        </Row>
        {/* <Row className="my-1 justify-content-center">
          <Col xl={8} className="">
            <Carousel fade interval={1000} data-aos="fade-up">
              {brandings.map((brand) => (
                <Carousel.Item key={brand._id}>
                  {console.log(`${brand.image}`)}
                  <img className="d-block w-100" src={`${brand.image}`} />
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row> */}
      </Container>
    </div>
  );
}

export default AboutUsScreen;
