import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../custom/BrandingScreen.css";
import {
  Row,
  Col,
  Container,
  Image,
  Card,
  Button,
  Carousel,
} from "react-bootstrap";
import { Link } from "react-router-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";

import brandings from "../branding.js";

function BrandingScreen() {
  let history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <Container className="">
        <Row className="mt-5">
          <Col>
            <Button
              className="secondaryButton px-3 py-0"
              onClick={() => history.goBack()}
            >
              {"<"}
            </Button>
          </Col>
        </Row>
        <Row className="text-center">
          <p className="subpageHeading" data-aos="fade-up">
            Branding
          </p>
        </Row>
        <Row className="mt-5 justify-content-center">
          <Col xl={8}>
            <Row className="subSubHeading mx-3">
              <p className="m-0 p-0" data-aos="fade-up">
                Developing a unique
              </p>
            </Row>
            <Row className="mt-3 mx-0 p-0">
              <Col className="d-flex">
                <Row>
                  <Col className="d-flex" xs={12} md={6}>
                    <Row className="m-0 p-0 justify-content-center">
                      <Card className="d-flex baseCard p-1" data-aos="fade-up">
                        <Card.Body>
                          <Row>
                            <Col>
                              <Card.Title
                                className="crazyNumbers2"
                                data-aos="fade-up"
                              >
                                <i class="fa-solid fa-chess-king"></i>
                              </Card.Title>
                            </Col>
                          </Row>
                          <Card.Title className="mb-3 mt-2 baseCardTitle">
                            Brand name
                          </Card.Title>
                          <Card.Text className="baseCardText">
                            <span>
                              Improve visibility, build credibility and{" "}
                              <span className="highlightText">trust</span> with
                              customers
                            </span>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Row>
                  </Col>
                  <Col className="d-flex" xs={12} md={6}>
                    <Row className="m-0 p-0 justify-content-center">
                      <Card className="d-flex baseCard p-1" data-aos="fade-up">
                        <Card.Body>
                          <Row>
                            <Col>
                              <Card.Title
                                className="crazyNumbers2"
                                data-aos="fade-up"
                              >
                                <i class="fa-brands fa-sketch"></i>
                              </Card.Title>
                            </Col>
                          </Row>
                          <Card.Title className="mb-3 mt-2 baseCardTitle">
                            Logo
                          </Card.Title>
                          <Card.Text className="baseCardText">
                            <span>
                              Unique and eye-catching logo can attract
                              customers, increase{" "}
                              <span className="highlightText">engagement</span>
                            </span>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className="d-flex">
                <Row>
                  <Col className="d-flex">
                    <Row className="m-0 p-0 justify-content-center">
                      <Card className="d-flex baseCard p-1" data-aos="fade-up">
                        <Card.Body>
                          <Row>
                            <Col>
                              <Card.Title
                                className="crazyNumbers2"
                                data-aos="fade-up"
                              >
                                <i class="fa-solid fa-eye"></i>
                              </Card.Title>
                            </Col>
                          </Row>
                          <Card.Title className="mb-3 mt-2 baseCardTitle">
                            Visual Style
                          </Card.Title>
                          <Card.Text className="baseCardText">
                            <span>
                              Color scheme, typography, graphic elements are
                              crucial aspects of{" "}
                              <span className="highlightText">
                                brand identity
                              </span>
                            </span>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Row>
                  </Col>
                  <Col className="d-flex">
                    <Row className="m-0 p-0 justify-content-center">
                      <Card className="d-flex baseCard p-1" data-aos="fade-up">
                        <Card.Body>
                          <Row>
                            <Col>
                              <Card.Title
                                className="crazyNumbers2"
                                data-aos="fade-up"
                              >
                                <i class="fa-solid fa-message"></i>
                              </Card.Title>
                            </Col>
                          </Row>
                          <Card.Title className="mb-3 mt-2 baseCardTitle">
                            Brand Message
                          </Card.Title>
                          <Card.Text className="baseCardText">
                            <span>
                              Communicates the unique{" "}
                              <span className="highlightText">value</span> and
                              benefits of the business and its products or
                              services
                            </span>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="text-center mt-5">
          <p className="subSubHeading" data-aos="fade-up">
            Portfolio
          </p>
        </Row>
        <Row className="my-1 justify-content-center">
          <Col xl={8} className="">
            <Carousel fade interval={1000} data-aos="fade-up">
              {brandings.map((brand) => (
                <Carousel.Item key={brand._id}>
                  <img className="d-block w-100" src={`${brand.image}`} />
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default BrandingScreen;
