import React, { useState, useEffect, useRef } from "react";
import "../custom/style.css";
import { Row, Col, Container, Image, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import "../images/branding.png";
// import Spline from "@splinetool/react-spline";

function HomeScreen() {
  const getStarted = useRef();
  const contactUs = useRef();
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const [scrolled, setScrolled] = useState(true);
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 100 || window.screen.width > 1800) {
        setScrolled(false);
      } else {
        setScrolled(true);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div>
      <Container>
        <Row className="homeIntro">
          <Col xs={12} md={6} xl={6}>
            <span data-aos="fade-up" className="homeHello p-0">
              Hello
            </span>
            <Row data-aos="fade-up">
              <Col className="mx-3 customCol px-0 mediaSpace">
                <span>You've successfuly reached the </span>
                <s className="mediaSpace">destination</s>
                <span> </span>
                <span className="beginningText p-2 mediaSpace">
                  &nbsp;beginning&nbsp;
                </span>
              </Col>
              <Row className="mt-5">
                <Col>
                  <Button
                    className="baseButton btn-sm"
                    onClick={() =>
                      getStarted.current.scrollIntoView({ behavior: "smooth" })
                    }
                  >
                    Get Started
                  </Button>
                  <Button
                    className=" mx-2 secondaryButton  btn-sm"
                    onClick={() => contactUs.current.scrollIntoView()}
                  >
                    Contact Us
                  </Button>
                </Col>
              </Row>
            </Row>
          </Col>

          <Col
            xs={12}
            md={6}
            xl={6}
            className="homeSecondCol backGrad p-4 veryHighMarginTopOnXs latestVentures"
            id="about"
            style={{ marginLeft: "0 !important" }}
            data-aos="fade-up"
          >
            <Row className="text-center">
              <h3 className="whiteFont" data-aos="fade-up">
                LATEST VENTURES
              </h3>
            </Row>
            <Row className="mt-3">
              <Col xl={6} md={6} xs={12} className="px-3 roundedCorners">
                <Col
                  className="glassBg2 roundedCorners p-3 text-center whiteFont"
                  data-aos="fade-up"
                >
                  <h5 className="whiteFont" style={{ textTransform: "none" }}>
                    MyEmi
                  </h5>
                  <p className="smallerFont">
                    Not just another EMI calculator. <br />
                    <span className="mt-2">
                      Try out the new{" "}
                      <span style={{ fontWeight: "600" }}>
                        'Modern EMI Calculator'
                      </span>{" "}
                      with limitless{" "}
                      <span style={{ fontWeight: "600" }}>'Compare'</span>{" "}
                      options
                    </span>
                  </p>
                  <Link
                    href="#"
                    onClick={() => openInNewTab("https://emi-calculator-gamma.vercel.app/")}
                    style={{
                      textTransform: "none !important",
                      color: "#007bff",
                    }}
                  >
                    <Button
                      className="glassBtn"
                      style={{ fontSize: "70%" }}
                      data-aos="fade-up"
                    >
                      TRY
                    </Button>
                  </Link>
                </Col>
              </Col>
              <Col
                xl={6}
                md={6}
                xs={12}
                className="px-3 roundedCorners marginTopOnXs d-flex"
              >
                <Col
                  className="glassBg2 roundedCorners p-3 text-center whiteFont"
                  data-aos="fade-up"
                >
                  <h5 className="whiteFont" style={{ textTransform: "none" }}>
                    DogsNShows
                  </h5>
                  <p className="smallerFont">
                    A fully functional and responsive E-commerce WebApp with
                    tactically designed components
                    <br />
                    Take a look at the beauty!
                  </p>
                  <Link
                    href="#"
                    onClick={() => openInNewTab("https://dogsnshows.herokuapp.com/")}
                    style={{
                      textTransform: "none !important",
                      color: "#007bff",
                    }}
                  >
                    <Button
                      className="glassBtn"
                      style={{ fontSize: "70%" }}
                      data-aos="fade-up"
                    >
                      TRY
                    </Button>
                  </Link>
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>
        {scrolled ? (
          <Row className="text-center" style={{ fontSize: "200%" }}>
            <p className="m-0 p-0" style={{ fontSize: "50%" }}>
              SCROLL
            </p>
            <i class="fa-solid fa-chevron-down"></i>
          </Row>
        ) : (
          ""
        )}

        <Row className="my-5 ms-3 gardBg" ref={getStarted}>
          <Col data-aos="fade-up" className="p-0">
            <p className="titleLargerFontSide">
              Get <br></br>
              <span className="beginningText px-2">
                &nbsp;off the mark&nbsp;
              </span>
            </p>
          </Col>
        </Row>

        <Row className="highMarginRow">
          <Col>
            <Card data-aos="fade-up" className="baseCard p-3">
              <Card.Body>
                <Row>
                  <Col>
                    <Card.Title className="crazyNumbers">1</Card.Title>
                  </Col>
                  <Col className="m-auto text-center">
                    <img
                      data-aos="fade-up"
                      src={require("../images/branding.png")}
                      className="cardImage"
                    />
                  </Col>
                </Row>
                <Row className="my-1">
                  <Row>
                    <h4 style={{ color: "grey" }}>
                      <i class="fa-solid fa-copyright"></i>{" "}
                      <i class="fa-solid fa-users"></i>{" "}
                      <i class="fa-solid fa-ranking-star"></i>{" "}
                    </h4>
                  </Row>
                </Row>
                <Card.Title className="mb-3 baseCardTitle">Branding</Card.Title>

                <Card.Text className="baseCardText">
                  Build a strong, consistent brand that stands out from the
                  competition and resonates with your{" "}
                  <span className="highlightText">target audience</span>
                </Card.Text>
                <Link to="/branding">
                  <Button className="baseButton btn-sm">Explore</Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card data-aos="fade-up" className="baseCard backGrad p-3">
              <Card.Body>
                <Row>
                  <Col>
                    <Card.Title
                      className="crazyNumbers"
                      style={{ color: "white" }}
                    >
                      2
                    </Card.Title>
                  </Col>
                  <Col className="m-auto text-center">
                    <img
                      data-aos="fade-up"
                      src={require("../images/uiux.png")}
                      className="cardImage"
                    />
                  </Col>
                </Row>
                <Row className="my-1">
                  <Row>
                    <h4 style={{ color: "white", opacity: "0.6" }}>
                      <i class="fa-solid fa-pen-to-square"></i>{" "}
                      <i class="fa-solid fa-palette"></i>{" "}
                      <i class="fa-solid fa-floppy-disk"></i>{" "}
                    </h4>
                  </Row>
                </Row>
                <Card.Title
                  className="mb-3 baseCardTitle"
                  style={{ color: "white" }}
                >
                  UI&nbsp;/&nbsp;UX design
                </Card.Title>
                <Card.Text
                  className="baseCardText"
                  style={{ color: "white", opacity: "0.6" }}
                >
                  Help startups create products that are{" "}
                  <span className="highlightText">intuitive</span>, easy to use,
                  and provide a positive user experience
                </Card.Text>
                <Link to="/uiux">
                  <Button className="baseButton btn-sm">Explore</Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card data-aos="fade-up" className="baseCard p-3">
              <Card.Body>
                <Row>
                  <Col>
                    <Card.Title className="crazyNumbers">3</Card.Title>
                  </Col>
                  <Col className="m-auto text-center">
                    <img
                      data-aos="fade-up"
                      src={require("../images/development.png")}
                      className="cardImage"
                    />
                  </Col>
                </Row>
                <Row className="my-1">
                  <Row>
                    <h4 style={{ color: "grey" }}>
                      <i class="fa-solid fa-code"></i>{" "}
                      <i class="fa-solid fa-gears"></i>{" "}
                    </h4>
                  </Row>
                </Row>
                <Card.Title className="mb-3 baseCardTitle">
                  Development
                </Card.Title>
                <Card.Text className="baseCardText">
                  Aim to create innovative, scalable, and secure software that
                  meets their unique needs and helps{" "}
                  <span className="highlightText">drive growth.</span>
                </Card.Text>
                <Link to="/dev">
                  <Button className="baseButton btn-sm">Explore</Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <div style={{ backgroundColor: "#f9f9f9" }}>
        <Row className="fullRow highMarginRow px-5 text-center">
          <p className="mt-5 titleFont">Accomplishments</p>
          <Row className="">
            <Row className="justify-content-center mt-3">
              <Col xl={3}>
                <Row className="justify-content-center">
                  <Col className="mx-3">
                    <Button className="numberOf p-3">20+</Button>
                  </Col>
                </Row>
                <Row className="text-center mt-2">
                  <p>Projects</p>
                </Row>
              </Col>
              <Col xl={3}>
                <Row className="justify-content-center">
                  <Col>
                    <Button className="numberOf p-3">10+</Button>
                  </Col>
                </Row>
                <Row className="text-center mt-2">
                  <p>Clients</p>
                </Row>
              </Col>
              <Col xl={3}>
                <Row className="justify-content-center">
                  <Col>
                    <Button className="numberOf p-3">5+</Button>
                  </Col>
                </Row>
                <Row className="text-center mt-2">
                  <p>Achievements</p>
                </Row>
              </Col>
            </Row>
          </Row>
        </Row>
        <Container>
          <Row className="px-5 pb-5 justify-content-around mt-5">
            <Col className="mx-3 " data-aos="fade-up" xl={3} xs={10}>
              <Row className="quote">
                <i class="fa-solid fa-quote-left quote"></i>
              </Row>
              <Row>
                I was blown away by the teamwork and synergy between this web
                development firm and our team. They seamlessly incorporated our
                ideas, yielding a stunning and functional website.
              </Row>
              <Row className="mt-3 text-center gradText">Nandakumar R</Row>
            </Col>
            <Col className="mx-3 " data-aos="fade-up" xl={3} xs={10}>
              <Row className="quote">
                <i class="fa-solid fa-quote-left quote"></i>
              </Row>
              <Row>
                Collaborating with this crew was a total pleasure. Their team
                was top-notch, responsive to our requirements, and delivered a
                website that went above and beyond our dreams.
              </Row>
              <Row className="mt-3 text-center gradText">Sudhakaran R</Row>
            </Col>
            <Col className="mx-3 " data-aos="fade-up" xl={3} xs={10}>
              <Row className="quote">
                <i class="fa-solid fa-quote-left quote"></i>
              </Row>
              <Row>
                They were able to capture the essence of our brand and create an
                online store that is both visually stunning and easy to navigate
              </Row>
              <Row className="mt-3 text-center gradText">Chithra S</Row>
            </Col>
          </Row>
        </Container>
      </div>

      <div className=" p-5 backGrad">
        <Row className="highMarginTop ">
          <Col data-aos="fade-up">
            <p
              className="mt-5 ms-3 titleLargerFont "
              style={{ color: "white" }}
            >
              <i class="fa-solid fa-cloud"></i>
            </p>
            <p className="ms-3 titleLargerFont " style={{ color: "white" }}>
              We've got more <br />
              under the roof.
            </p>
          </Col>
        </Row>

        <Row className="highMarginRow justify-content-center">
          <Col
            data-aos="fade-up"
            xl={4}
            xs={10}
            className="d-flex justify-content-center"
          >
            <Row className="m-0 p-0 justify-content-center">
              <Card className="d-flex baseCard p-3">
                <Card.Body>
                  <Row>
                    <Col>
                      <Card.Title className="crazyNumbers">I</Card.Title>
                    </Col>
                    <Row className="my-1">
                      <Row>
                        <h4
                          style={{ color: "grey" }}
                          className="mediaSmallerFont"
                        >
                          <i class="fa-solid fa-store"></i>{" "}
                          <i class="fa-solid fa-cart-shopping"></i>{" "}
                          <i class="fa-solid fa-truck-fast"></i>{" "}
                          <i class="fa-solid fa-receipt"></i>
                        </h4>
                      </Row>
                    </Row>
                  </Row>
                  <Card.Title className="mb-3 baseCardTitle">
                    E Commerce
                  </Card.Title>
                  <Card.Text className="baseCardText">
                    <span>
                      Establish an online presence,{" "}
                      <span className="highlightText">increase sales</span>, and
                      streamline your operations
                    </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Row>
          </Col>
          <Col
            data-aos="fade-up"
            xl={4}
            xs={10}
            className="d-flex justify-content-center"
          >
            <Row className="m-0 p-0 justify-content-center">
              <Card className="d-flex glassBg p-3">
                <Card.Body>
                  <Row>
                    <Col>
                      <Card.Title
                        className="crazyNumbers"
                        style={{ color: "white" }}
                      >
                        II
                      </Card.Title>
                    </Col>
                    <Row className="my-1">
                      <Row>
                        <h4
                          style={{ color: "white", opacity: "0.6" }}
                          className="mediaSmallerFont"
                        >
                          <i class="fa-solid fa-microchip"></i>{" "}
                          <i class="fa-solid fa-memory"></i>{" "}
                          <i class="fa-solid fa-robot"></i>
                        </h4>
                      </Row>
                    </Row>
                  </Row>
                  <Card.Title
                    className="mb-3 baseCardTitle"
                    style={{ color: "white" }}
                  >
                    AI / ML
                  </Card.Title>
                  <Card.Text
                    className="baseCardText"
                    style={{ color: "white", opacity: "0.6" }}
                  >
                    <span>
                      Leverage the power of AI/ML to automate processes, gain
                      insights, and improve{" "}
                      <span
                        className="highlightText"
                        style={{ opacity: "1 !important" }}
                      >
                        decision-making
                      </span>
                    </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Row>
          </Col>
          <Col
            data-aos="fade-up"
            xl={4}
            xs={10}
            className="d-flex  justify-content-center"
          >
            <Row className="m-0 p-0 justify-content-center">
              <Card className="d-flex baseCard p-3">
                <Card.Body>
                  <Row>
                    <Col>
                      <Card.Title className="crazyNumbers">III</Card.Title>
                    </Col>
                    <Row className="my-1">
                      <Row>
                        <h4
                          style={{ color: "grey" }}
                          className="mediaSmallerFont"
                        >
                          <i class="fa-solid fa-gears"></i>{" "}
                          <i class="fa-solid fa-network-wired"></i>{" "}
                          <i class="fa-solid fa-cloud"></i>{" "}
                        </h4>
                      </Row>
                    </Row>
                  </Row>
                  <Card.Title className="mb-3 baseCardTitle">
                    IoT and Automation
                  </Card.Title>
                  <Card.Text className="baseCardText">
                    <span>
                      software, and services to
                      <span className="highlightText">
                        optimize operations
                      </span>{" "}
                      and enhance user experience
                    </span>
                  </Card.Text>
                </Card.Body>
                {/* <Row className="m-2 p-0">
                  <Button className="baseButton btn-sm">Explore</Button>
                </Row> */}
              </Card>
            </Row>
          </Col>
        </Row>
        <Row className="m-auto ms-3 mb-5 glassBg p-3">
          <Col
            data-aos="fade-up"
            style={{ color: "white" }}
            classname="glassBg"
          >
            <p className="titleLargerFontSide">
              <i class="fa-solid fa-door-open"></i>
              <br /> Join the movement of seamless experience at <br></br>
              <span data-aos="fade-right" className="beginningText px-2">
                &nbsp;very low costs ! &nbsp;
              </span>
            </p>
          </Col>
        </Row>
      </div>

      <Container>
        <Row className="highMarginBottom mt-5" ref={contactUs}>
          <Row className="">
            <Col data-aos="fade-up">
              <p
                className="mt-5 ms-3 titleLargerFont"
                style={{ color: "black" }}
              >
                Get in touch <br />
                With Us
              </p>
            </Col>
          </Row>
          <Row className="mt-3 justify-content-center centerMedia">
            <Row className="">
              <p data-aos="fade-up">
                <i class="fa-solid fa-phone gradText"></i>
                <span className="mx-3" style={{ fontSize: "100%" }}>
                  +91 87545 19815
                </span>
              </p>
            </Row>
            <Row className="">
              <Col>
                <Row>
                  <p data-aos="fade-up">
                    <i class="fa-solid fa-envelope gradText"></i>
                    <span className="mx-3" style={{ fontSize: "100%" }}>
                      {" "}
                      inbox@exia.co.in
                    </span>
                  </p>
                </Row>
              </Col>
            </Row>
            <Row className="">
              <Col>
                <Row>
                  <p data-aos="fade-up">
                    <i class="fa-solid fa-location-dot gradText"></i>
                    <span className="mx-3" style={{ fontSize: "100%" }}>
                      {" "}
                      Chennai, India
                    </span>
                  </p>
                </Row>
              </Col>
            </Row>
          </Row>
          <Col className="m-auto mt-3"></Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeScreen;
