import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../custom/BrandingScreen.css";
import {
  Row,
  Col,
  Container,
  Image,
  Card,
  Button,
  Carousel,
} from "react-bootstrap";
import { Link } from "react-router-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";

import uiuxs from "../uiux.js";

function UiuxScreen() {
  let history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div>
      <Container className="">
        <Row className="mt-5">
          <Col>
            <Button
              className="secondaryButton px-3 py-0"
              onClick={() => history.goBack()}
            >
              {"<"}
            </Button>
          </Col>
        </Row>
        <Row className="text-center">
          <p className="subpageHeading" data-aos="fade-up">
            UI / UX
          </p>
        </Row>
        <Row className="mt-3 justify-content-center">
          <Col xl={8}>
            <Row className="subSubHeading mx-3">
              <p className="m-0 p-0"></p>
            </Row>
            <Row className="mt-3 mx-0 p-0">
              <Col className="d-flex">
                <Row>
                  <Col className="d-flex" xs={12} md={6}>
                    <Row className="m-0 p-0 justify-content-center">
                      <Card className="d-flex baseCard p-1" data-aos="fade-up">
                        <Card.Body>
                          <Row>
                            <Col>
                              <Card.Title
                                className="crazyNumbers2"
                                data-aos="fade-up"
                              >
                                <i class="fa-solid fa-wand-magic-sparkles"></i>
                              </Card.Title>
                            </Col>
                          </Row>
                          <Card.Title className="mb-3 mt-2 baseCardTitle">
                            Usability
                          </Card.Title>
                          <Card.Text className="baseCardText">
                            <span>
                              improves the usability of a website and helps
                              create a{" "}
                              <span className="highlightText">positive</span>{" "}
                              user experience
                            </span>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Row>
                  </Col>
                  <Col className="d-flex" xs={12} md={6}>
                    <Row className="m-0 p-0 justify-content-center">
                      <Card className="d-flex baseCard p-1" data-aos="fade-up">
                        <Card.Body>
                          <Row>
                            <Col>
                              <Card.Title
                                className="crazyNumbers2"
                                data-aos="fade-up"
                              >
                                <i class="fa-solid fa-globe"></i>
                              </Card.Title>
                            </Col>
                          </Row>
                          <Card.Title className="mb-3 mt-2 baseCardTitle">
                            Accessibility
                          </Card.Title>
                          <Card.Text className="baseCardText">
                            <span>
                              Makes it{" "}
                              <span className="highlightText">easier</span> for
                              all users to find what they are looking for
                            </span>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="text-center mt-5">
          <p className="subSubHeading" data-aos="fade-up">
            Portfolio
          </p>
        </Row>
        <Row className="my-1 justify-content-center">
          <Col xl={8} className="">
            <Carousel fade interval={1000} data-aos="fade-up">
              {uiuxs.map((uiux) => (
                <Carousel.Item key={uiux._id}>
                  {console.log(`${uiux.image}`)}
                  <img className="d-block w-100" src={`${uiux.image}`} />
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default UiuxScreen;
