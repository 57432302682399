const brandings = [
  {
    _id: "1",
    name: "B&B - Bangs and Bangs",
    image: "/images/branding/b&b.jpg",
    description: "Fresh and healthy from gardens across California",
    brand: "Exia",
    category: "nuts",
  },
  {
    _id: "2",
    name: "Apollo Artworks",
    image: "/images/branding/apollo.jpg",
    description: "Fresh and healthy from gardens across California",
    brand: "Exia",
    category: "nuts",
  },
  {
    _id: "3",
    name: "Awareness Banner",
    image: "/images/branding/awareness.jpg",
    description: "Fresh and healthy from gardens across California",
    brand: "Exia",
    category: "nuts",
  },
  {
    _id: "4",
    name: "Breathin",
    image: "/images/branding/breathin.jpg",
    description: "Fresh and healthy from gardens across California",
    brand: "Exia",
    category: "dryFruits",
  },
  {
    _id: "5",
    name: "Exia",
    image: "/images/branding/exia.jpg",
    description: "Fresh and healthy from gardens across California",
    brand: "Exia",
    category: "nuts",
  },
  {
    _id: "6",
    name: "MyTaXpert",
    image: "/images/branding/mytaxpert.jpg",
    description: "Fresh and healthy from gardens across California",
    brand: "Exia",
    category: "nuts",
  },
  {
    _id: "7",
    name: "remede",
    image: "/images/branding/remede.jpg",
    description: "Fresh and healthy from gardens across California",
    brand: "Exia",
    category: "nuts",
  },
  {
    _id: "8",
    name: "RockRitual",
    image: "/images/branding/rock.jpg",
    description: "Fresh and healthy from gardens across California",
    brand: "Exia",
    category: "nuts",
  },
  {
    _id: "9",
    name: "SmokedHub",
    image: "/images/branding/smokedHub.jpg",
    description: "Fresh and healthy from gardens across California",
    brand: "Exia",
    category: "nuts",
  },
  {
    _id: "10",
    name: "SmokedHub2",
    image: "/images/branding/smokedHub2.jpg",
    description: "Fresh and healthy from gardens across California",
    brand: "Exia",
    category: "nuts",
  },
  {
    _id: "11",
    name: "Techmii",
    image: "/images/branding/techmii.jpg",
    description: "Fresh and healthy from gardens across California",
    brand: "Exia",
    category: "nuts",
  },
  {
    _id: "12",
    name: "TRP",
    image: "/images/branding/trp.jpg",
    description: "Fresh and healthy from gardens across California",
    brand: "Exia",
    category: "nuts",
  },
  {
    _id: "13",
    name: "Yenkey",
    image: "/images/branding/yenkey.jpg",
    description: "Fresh and healthy from gardens across California",
    brand: "Exia",
    category: "nuts",
  },
];
export default brandings;
