import React, { useState, useEffect, useRef, useDebugValue } from "react";
import projects from "../../projects";
import {
  Row,
  Col,
  Container,
  Image,
  Card,
  Button,
  FormGroup,
  Form,
  Alert,
} from "react-bootstrap";
function TempleTownTrips() {
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState(false);

  const submitHandler = (e) => {
    if (phone === "8883365102") {
      setStatus(true);
    }
  };

  const phoneNumberHandler = (phno) => {
    setStatus(false);
    setPhone(phno);
  };

  return (
    <div>
      {!status && (
        <Container className="mt-5 px-5">
          <Col
            style={{
              boxShadow:
                "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
            }}
          >
            <Row className="mx-0 px-0 text-center">
              <Form>
                <Form.Group controlId="email">
                  <Form.Label>Enter Registered Phone Number</Form.Label>
                  <Form.Control
                    style={{ borderRadius: "30px" }}
                    type="phone"
                    placeholder="Enter Phone Number"
                    value={phone}
                    onChange={(e) => phoneNumberHandler(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Form>
              <Row className="justify-content-center">
                <Button
                  onClick={submitHandler}
                  className="mt-3 w-50 mx-0 px-0"
                  style={{ backgroundColor: "#4056a1", borderRadius: "30px" }}
                >
                  Submit
                </Button>
              </Row>
            </Row>
          </Col>
        </Container>
      )}

      {!status && (
        <Container className="px-5 mt-5">
          <Col>
            <Row className="justify-content-center">
              <Alert
                style={{ borderRadius: "30px", fontSize: "75%" }}
                variant="info"
                className="px-3"
              >
                Please enter a valid phone number
              </Alert>
            </Row>
          </Col>
        </Container>
      )}
      {status && <img className="d-block w-100" src={`${projects[0].image}`} />}
    </div>
  );
}

export default TempleTownTrips;
