import React, { useEffect, useRef } from "react";
import {
  Row,
  Button,
  Col,
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Image,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "../images/exiaLogo.png";
import "../custom/style.css";
import { Link } from "react-router-dom";

function Header() {
  const scollToRef = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar
        bg="light"
        expand="lg"
        className="navbar p-0"
        style={{zIndex:'100', width:'100%', top:'0'}}
      >
        <Container>
          <Col className="navbarTitle">
            <Nav>
              <LinkContainer to="/">
                <Nav.Link >
                  <img
                  className="navbarLogo"
                    src={require("../images/ExiaLogo2.png")}
                  ></img>
                </Nav.Link>
              </LinkContainer>
            </Nav>
          </Col>
          <Col className="text-center disappear">Exia Tech.</Col>
          <Col>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="float-end"
            />
            <Col className="float-end">
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto ">
                  <LinkContainer to="/about">
                    <Nav.Link className="navbarLink">About</Nav.Link>
                  </LinkContainer>
                </Nav>
              </Navbar.Collapse>
            </Col>
          </Col>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
